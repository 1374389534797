import Vue from "vue";
import Vuex from "vuex";
import CreatePersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [CreatePersistedState()],
  state: {
    offers: null,
    snacks: null,
    isAuthenticated: false,
    username: "",
    userId: "",
    cardNumber: "",
    balance: "",
    spinner_quantity: 0,
    time: "",
    notification: {
      show: false,
      type: "",
      message: "",
      description: null,
    },
    alert: {
      type: "",
    },
  },
  mutations: {
    clearState(state) {
      state.offers = null;
      state.snacks = null;
      state.isAuthenticated = false;
      state.username = "";
      state.userId = "";
      state.cardNumber = "";
      state.balance = "";
      state.spinner_quantity = 0;
      state.time = "";
      state.notification = {
        show: false,
        type: "",
        message: "",
        description: null,
      };
      state.alert = {
        type: "",
      };
    }, 
    updateOffers(state, payload) {
      state.offers = payload;
    },
    updateSnacks(state, payload) {
      state.snacks = payload;
    },
    updateUserInfo(state, payload) {
      state.username = payload.username;
      state.userId = payload.userId;
      state.cardNumber = payload.cardNumber;
      state.balance = payload.balance;
      state.spinner_quantity = payload.spinner_quantity;
      state.time = payload.time;
      state.first_name = payload.first_name;
    },
    updateIsAuthenticated(state, value) {
      state.isAuthenticated = value;
    },
    updateNotification(state, payload) {
      state.notification = payload;
    },
    showAlert(state, payload) {
      state.alert = payload;
    },
    //TODO success
    clearNotification(state) {
      state.notification = {
        show: false,
        type: state.notification.type,
        message: "",
      };
    },
    updateCurdNumber(state, { cardNumber }) {
      state.cardNumber = cardNumber;
    },
    updateBalance(state, { balance }) {
      state.balance = balance;
    },
    updateSpinnerQuantity(state, { spinner_quantity }) {
      state.spinner_quantity = spinner_quantity;
    },
    updateUserTime(state, { time }) {
      state.time = time;
    },
  },
  actions: {
    setUserInfo({ commit }, payload) {
      commit("updateUserInfo", payload);
    },
    setIsAuthenticated({ commit }, value) {
      commit("updateIsAuthenticated", value);
    },
    setNotification({ commit }, payload) {
      commit("updateNotification", payload);
    },
    setAlert({ commit }, payload) {
      commit("showAlert", payload);
    },
    setCardNumber({ commit }, payload) {
      commit("updateCurdNumber", payload);
    },
    setBalance({ commit }, payload) {
      commit("updateBalance", payload);
    },
    setSpinnerQuantity({ commit }, payload) {
      commit("updateSpinnerQuantity", payload);
    },
    setUserTime({ commit }, payload) {
      commit("updateUserTime", payload);
    },
  },
});
