import Vue from "vue";
import App from "./App.vue";
import veeValidate from "./plugins/vee-validate";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import axios from "./plugins/axios";
import fileSelector from "./plugins/vue-file-selector";
import VueMask from "v-mask";
import VueConfetti from "vue-confetti";
import GlobalVariables from "./globalVariables.json";
import "@/styles/main.scss";
import fspCashService from './plugins/cash-machine';

Vue.prototype.$axios = axios;
Vue.prototype.$vars = GlobalVariables;
Vue.prototype.$eventBus = new Vue();

Vue.config.productionTip = false;

Vue.use(VueMask);
Vue.use(VueConfetti);

new Vue({
  vuetify,
  veeValidate,
  router,
  store,
  fileSelector,
  fspCashService,
  render: (h) => h(App),
}).$mount("#app");
