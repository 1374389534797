<template>
  <v-dialog max-width="500px" v-model="notification.show">
    <div class="notif">
      <div class="notif__title">
        {{ notification.message }}
      </div>
      <div v-if="notification.description" class="notif__subtitle">
        {{ notification.description }}
      </div>
      <div class="notif__button">
        <button class="notif__button" @click="clearNotification">Ok</button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "Notification",
  computed: {
    ...mapState(["notification"]),
    currentRouteName() {
      return this.$route.name;
    },
  },
  watch: {
    "notification.show"(val) {
      if (!val) {
        this.clearNotification();
      }
    },
  },
  methods: {
    ...mapMutations(["clearNotification"]),
    ...mapActions(["setNotification"]),
  },
};
</script>

<style lang="scss" scoped></style>
