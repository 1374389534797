import Vue from "vue";
import VeeValidate, { Validator } from "vee-validate";

Vue.use(VeeValidate, {
  events: "blur",
  errorBagName: "errorsBags",
  fieldsBagName: "fieldsBags",
});
Validator.localize("en");
export default new VeeValidate();
