import axios from "axios";
import store from "../store/store"
import globalVariables from "../globalVariables.json";
import router from "../router";

require('dotenv').config()
const instance = axios.create({
  baseURL: globalVariables.BACKEND_BASE_URL,
  timeout: 0,

  withCredentials: true
})

instance.interceptors.response.use(response => response, error => {
  if (error.response.status === 401) {
    store.dispatch('setIsAuthenticated', false)
    router.push({name: 'login'})
  }
  store.dispatch('setNotification', {
    show: true,
    type: 'error',
    message: error.response.data.message
  })
})

export default instance
