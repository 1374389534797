import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/store";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    redirect: {
      name: "dashboard",
    },
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("../views/Search.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/google-search',
    name: 'GoogleSearch',
    component: () => import('../views/GoogleSearch.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("../views/SignUp.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/signup-4digit",
    name: "signup-4digit",
    component: () => import("../views/SignUp4Digit.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/forgot",
    name: "forgot",
    component: () => import("../views/Forgot.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/computers",
    name: "computers",
    component: () => import("../views/Computers.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/computers/rotate180",
    name: "computers/rotate180",
    component: () => import("../views/ComputersRotate180.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard/addtime",
    name: "addtime",
    component: () => import("../components/AddTime.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard/addspins",
    name: "addspins",
    component: () => import("../components/AddSpins.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard/addprints",
    name: "addprints",
    component: () => import("../components/AddPrints.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard/snacks",
    name: "snacks",
    component: () => import("../components/BuySnacks.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard/spinner",
    name: "spinner",
    component: () => import("../views/Spinner.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard/logs",
    name: "logs",
    component: () => import("../components/UserLogs.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/chat",
    name: "chat",
    component: () => import("../views/Support.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/chat",
    name: "adminChat",
    component: () => import("../views/Support.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/admin/chat/:user",
    name: "adminChat",
    component: () => import("../views/Support.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/successSignup",
    name: "successSignup",
    component: () => import("../views/PasswordSupport.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "*",
    name: "pageNotFound",
    component: () => import("../views/PageNotFound.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta?.requiresAuth)) {
    if (store.state.isAuthenticated) {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export default router;
