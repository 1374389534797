<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
    <NotificationPopup />
  </v-app>
</template>

<script>
import NotificationPopup from "./components/common/modal/NotificationPopup";

export default {
  components: {
    NotificationPopup,
  },
};
</script>

<style lang="scss">
#app {
  font-family: "MyFont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
}

.v-card {
  padding: 15px;
}
</style>
