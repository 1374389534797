import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#6179ff',
                accent: '#264088',
                warning: '#f1d236',
                error: '#fc5f75',
                success: '#5aa228',
                red: '#CD3023',
                input: '#ebebeb',
            },
        },
    },
});
